import * as Realm from "realm-web"
import {
  ApolloClient,
  HttpLink,
  InMemoryCache
} from "@apollo/client"
import { getUserData } from '@utils'

/*import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

const httpLink = createHttpLink({
   uri:'http://localhost:4000/'
})

const client = new ApolloClient({
    connectToDevTools: true,
    cache : new InMemoryCache(),
    link: httpLink
})*/
const  APP_ID = 'app-base-foway'
// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID)
// Gets a valid Realm user access token to authenticate requests

export async function removeUser(newUser) {
  await app.removeUser(newUser)
}


export async function registerLogin(email = '', password = '') {
  try {
    const currentUser = app.currentUser
    await app.emailPasswordAuth.registerUser({email, password})
    const newUser = await app.logIn(Realm.Credentials.emailPassword(email, password))
 
    // We have the user id now, let's log them out and remove them from the local device
    await app.removeUser(newUser)

    // Switch back to the original currentUser
    app.switchUser(currentUser)
    // Return the user Id to setup Address, Phone, and so on.
    return newUser.id
     
  } catch (error) {
    console.log(error)
    throw error
  }
  
}

export function logout() {
  app.currentUser.logOut()
}

export function deleteUser(userId) {
 /* const { user } = {
    id: userId
  }
  app.deleteUser(user)
  .then(() => {
    console.log("Usuario eliminado correctamente")
  })
  .catch((error) => {
    console.error("Error al eliminar usuario:", error)
  })*/
}

export async function getValidAccessToken(email = '', password = '') {
  // Guarantee that there's a logged in user with a valid access token
   if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    const credentials = Realm.Credentials.emailPassword(email, password)
    await app.logIn(credentials)
    //return newUser
  } else {
    // An already logged in user's access token might be stale. Tokens must be refreshed after 
    // 30 minutes. To guarantee that the token is valid, we refresh the user's access token.
    await app.currentUser.refreshAccessToken()
  }
  return app.currentUser
}
 //export  const   getValidAccessToken = async (email, password) => {
  // const authedUser = await app.logIn(Realm.Credentials.emailPassword(email, password))
   //const authedUser = await app.logIn(credentials)
  //  setUser(authedUser);
   // console.log('respuesta', authedUser)
  //return authedUser
  // Guarantee that there's a logged in user with a valid access token
 /* if (!app.currentUser) {
     console.log('usuario')
    await app.logIn(Realm.Credentials.emailPassword(email, password))
  
  } else {
      await app.currentUser.refreshAccessToken()
  }
  return app.currentUser*/
//}
// Configure the ApolloClient to connect to your app's GraphQL endpoint
const client = new ApolloClient({
  connectToDevTools: true,
  link: new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      //const user = getUserData()
      //console.log(user.accessToken)
      const accessToken = await getValidAccessToken()
      options.headers.Authorization = `Bearer ${accessToken.accessToken}`
      return fetch(uri, options)
    }
  }),
  cache: new InMemoryCache()
})


export default client